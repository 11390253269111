import {handleErrorWithSentry, replayIntegration} from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

const hostname = window.location.hostname;
let environment = 'production';

if (hostname.includes('dev.netlify.app')) {
	environment = 'development';
} else if (hostname.includes('stage.netlify.app')) {
	environment = 'staging';
}

// Set replay session sample rate based on the environment
const replaysSessionSampleRate = environment === 'development' ? 1.0 : 0.1;

if (
	environment === 'development' ||
	environment === 'staging' ||
	environment === 'production'
) {
	Sentry.init({
		dsn: 'https://d1db8eb136c7f8b65b20aefbbd211c0a@o4506510035517440.ingest.us.sentry.io/4508406666297344',
		tracesSampleRate: 1.0,
		replaysSessionSampleRate,
		replaysOnErrorSampleRate: 1.0,
		integrations: [replayIntegration()],
		environment
	});
}

export const handleError =
	environment === 'production' || environment === 'staging'
		? handleErrorWithSentry()
		: ({error}) => {
			console.error(error);
		};
